export const MEDIA_URL = 'https://media'+process.env.REACT_APP_LOCAL_BACKEND_URL.substring(process.env.REACT_APP_LOCAL_BACKEND_URL.indexOf("."),process.env.REACT_APP_LOCAL_BACKEND_URL.length)
export const OFFER_URL = 'https://offer'+process.env.REACT_APP_LOCAL_BACKEND_URL.substring(process.env.REACT_APP_LOCAL_BACKEND_URL.indexOf("."),process.env.REACT_APP_LOCAL_BACKEND_URL.length)
export const LOG_URL = 'https://log'+process.env.REACT_APP_LOCAL_BACKEND_URL.substring(process.env.REACT_APP_LOCAL_BACKEND_URL.indexOf("."),process.env.REACT_APP_LOCAL_BACKEND_URL.length)

/* export const MEDIA_URL = 'http://localhost:8084/api/'
export const OFFER_URL = 'http://localhost:8085/api/'
export const LOG_URL = 'http://localhost:8082/api/' */

export const price_multiplier = 1.0854 // Commissione sul prezzo di vendita
export const refreshTokenIntervalDuration = 30*1000 // 30 seconds
export const tokenResidualValidityAllowed = 60*1000 // 1 minute, renew attempt will be done at 5x of this
export const hiddenFields = ['uuid','winevalue_uuid','code','memo','parent_uuid','model','parent_model','createdby','committed','committedby','modified','modifiedby','deleted','deletedby','ownedby','parent_origin','capabilities','salt','password','verification_token']
export const tableAdditionalHiddenFields = ['images','seller_address','seller_city','seller_zip','seller_phone','seller_taxidcode','buyer_address','buyer_city','buyer_zip','buyer_phone','buyer_taxidcode','buyer_email','ecommerce_buyer_id','ecommerce_shipment_id']
export const numberFields = ['number','integer','positivenumber','positiveinteger']
export const timeFields = ['date','datetime','time','timerange']
export const paginationOptions = [50,100,200,500,1000]
export const errorTable = [
    {code: 'E001', desc: 'Repository non disponibile'},
    {code: 'E101', desc: 'Errore nella sintassi della richiesta'},
    {code: 'E301', desc: 'Non hai i permessi di effettuare questa chiamata'},
    {code: 'E102', desc: 'Sono stati richiesti troppi oggetti'},
    {code: 'E103', desc: 'Oggetto non trovato'},
    {code: 'E104', desc: 'Formato non valido'},
    {code: 'E105', desc: 'Funzione non riconosciuta'},
    {code: 'E106', desc: 'Campo non riconosciuto'},
    {code: 'E107', desc: 'Valore campo non valido'},
    {code: 'E108', desc: 'Campo ordinamento non riconosciuto'},
    {code: 'E109', desc: 'Id oggetto non valido'},
    {code: 'E110', desc: 'Non è possibile committare due volte'},
    {code: 'E111', desc: 'Non è possibile cancellare due volte'},
    {code: 'E112', desc: 'Campo non riconosciuto al salvataggio'},
    {code: 'E113', desc: 'Campo non valido al salvataggio'},
    {code: 'E114', desc: 'Campo non modificabile al salvataggio'},
    {code: 'E115', desc: 'Campo obbligatorio non compilato al salvataggio'},
    {code: 'E116', desc: 'Campo univoco duplicato al salvataggio'}
]
export const maxFrontendUploadSize = 10485760
export const offersFilter = {
    "func": "or",
    "fields": [
        {
            "field": "offerstatus",
            "value": "nuovo",
            "func": "contains"
        },
        {
            "field": "offerstatus",
            "value": "abbinata",
            "func": "contains"
        },
        {
            "field": "offerstatus",
            "value": "pubblicare",
            "func": "contains"
        },
        {
            "field": "offerstatus",
            "value": "pubblicata",
            "func": "contains"
        },
        {
            "field": "offerstatus",
            "value": "annulla",
            "func": "contains"
        }
    ]
}
export const ordersFilter = {
    "func": "or",
    "fields": [
        {
            "field": "offerstatus",
            "value": "venduta",
            "func": "contains"
        }
    ]
}
