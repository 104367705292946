import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import { loadModels } from '../common'
import { useSelector } from 'react-redux'
import { Tooltip } from '@mui/material'

const Profile = () => {

    const apiModel = 'user'

    const initialUserState = {
        uuid: '',
        username: '',
        given_name: '',
        family_name: '',
        password: '',
        password2: '',
        telephone: '',
        birth_date: '',
        tax_id_code: '',
        address_street: '',
        address_number: '',
        address_city: '',
        address_zip: '',
    }
    const [userData,setUserData] = useState(initialUserState)
    const [userLoaded,setUserLoaded] = useState(false)
    const [canSubmitUser,setCanSubmitUser] = useState(false)
    const initialValidationState = {
        'uuid':{valid: false,touched: false, message: ''},
        'username':{valid: false,touched: false, message: 'Inserisci una mail valida'},
        'given_name': {valid: false,touched: false, message: 'Il nome è richiesto'},
        'family_name': {valid: false,touched: false, message: 'Il cognome è richiesto'},
        'password': {valid: false,touched: false, message: 'La password deve essere compresa fra 8 e 16 caratteri ed includere un numero e un simbolo scelto tra i seguenti: ! ? @ # $ % ^ & * -'},
        'password2': {valid: false,touched: false, message: 'Le due password non coincidono'},
        'telephone': {valid: false,touched: false, message: 'Devi inserire un cellulare italiano valido, il prefisso internazionale è opzionale'},
        'birth_date': {valid: false,touched: false, message: 'Inserisci una data valida, devi avere almeno 18 anni'},
        'tax_id_code': {valid: false,touched: false, message: 'Inserisci un codice fiscale valido'},
        'address_street': {valid: false,touched: false, message: 'Inserisci la via/piazza, ecc.'},
        'address_number': {valid: false,touched: false, message: 'Inserisci il numero civico'},
        'address_city': {valid: false,touched: false, message: 'Inserisci la città di residenza'},
        'address_zip': {valid: false,touched: false, message: 'Inserisci un CAP valido'},
    }
    const [validation, setValidation] = useState(initialValidationState)
    const [passwordInputType,setPasswordInputType] = useState('password')
    const user = useSelector((state)=> state.user)
    const navigate = useNavigate()

    // Load user data first time
    useEffect(()=>{
        const excludedFieldsArray = ['canlogin','capabilities','committed','created','groups','memo','model','modified','profile_completed','mail_verified']
        if(!userLoaded){
            loadModels(process.env.REACT_APP_LOCAL_AUTH_URL,apiModel,user.id,user.token).then((result) => {
                if(result && result.result){
                    let usr = result.result,
                        tmp_user = initialUserState
                    for(let k in usr){
                        for(let j in usr[k]){
                            if(!excludedFieldsArray.includes(j)) {
                                if(j === 'birth_date'){
                                    let o = new Date(usr[k][j]*1000*60*60*24),
                                    nd = o.getFullYear()+'-'+(o.getMonth()<9?'0':'')+(1+o.getMonth())+'-'+(o.getDate()<10?'0':'')+o.getDate()
                                    tmp_user[j] = nd
                                } else {
                                    tmp_user[j] = usr[k][j]
                                }
                            }
                        }
                    }
                    for(let k in tmp_user){
                        check_validation({"name":k,"value":tmp_user[k]})
                    }
                    setUserData(tmp_user)
                    setUserLoaded(true)
                } else {
                    if(result.error){
                        console.log('Impossibile caricare il profilo utente: '+result.error)
                        toast.error('Impossibile caricare il profilo utente: '+result.error)
                    }
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.id, user.token, userData, userLoaded])

    const checkIfCanSubmitUser = () => {
        const checkValidationStatus = () => {
            for(let k in validation){
                if(!validation[k].valid) return false
            }
            return true
        }
        if(checkValidationStatus()){
            setCanSubmitUser(true)
        } else {
            setCanSubmitUser(false)
        }
    }

    const check_validation = (field) => {
        const calculateAge = (birthDate) => {
            let ageDifMs = Date.now() - birthDate.getTime(),
                ageDate = new Date(ageDifMs);
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        }
        const check_single_field = (fieldName,value) => {
            if(fieldName === 'username'){
                let rx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
                if(rx.test(value)) return true
                return false
            } else if (fieldName === 'telephone'){
                let rx = /^((?:0039)?|(?:\+39)?)3[0-9]{8,9}$/
                if(rx.test(value)) return true
                return false
            } else if(fieldName === 'password' && value.length){
                let rx = /^(?=.*[0-9])(?=.*[!@#$%^&*?-])[a-zA-Z0-9!@#$%^&*?-]{8,16}$/
                if(rx.test(value)) return true
                return false
            } else if (fieldName === 'password2') {
                if(userData['password']===value) return true
                return false
            } else if (fieldName === 'birth_date' && value.length) {
                let birthDate = new Date(value)
                if ((birthDate.getTime() < Date.now()) && (calculateAge(birthDate) >= 18)) return true
                return false
            } else if (fieldName === 'tax_id_code' && value.length) {
                let rx = /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/
                if(rx.test(value)) return true
                return false
            } else if (fieldName === 'address_zip' && value) {
                if ((value <= 99999) && (value >= 10000)) return true
                return false
            } else if(['birth_date','tax_id_code','address_street','address_number','address_city','address_zip','password'].includes(fieldName) && !value.length) {
                return true
            } else {
                if(value.length) return true
                return false
            }
        }
        let newVal = validation
        if(newVal[field.name]){
            newVal[field.name].touched = true
            newVal[field.name].valid = check_single_field(field.name,field.value)
            if(field.name === 'password'){
                // Check password2 field if modifying password field
                newVal['password2'].touched = true
                newVal['password2'].valid = check_single_field('password2',userData['password2'])
            }
            setValidation({...newVal})
        }
        checkIfCanSubmitUser()
    }

    const handleUserChange = (event) => {
        let dataCopy = userData
            dataCopy[event.target.name] = event.target.value
        
        setUserData({...dataCopy})
        check_validation(event.target)
    }

    const cancelModifications = (e) => {
        e.preventDefault()
        setUserLoaded(false)
    }

    const backtoHome = (e) => {
        e.preventDefault()
        setUserLoaded(false)
        navigate('/')
    }

    const handleSave = async (e) => {
        e.preventDefault()
        setCanSubmitUser(false)
        const url = process.env.REACT_APP_LOCAL_AUTH_URL + apiModel + '/save'
        let postData = {}
        for(let k in userData){
            if(k !== 'password' && k !== 'password2' && k !== 'birth_date'){
                if(typeof userData[k] !== 'undefined') postData[k]=userData[k]
            }
            if(k==='birth_date'){
                let o = new Date(userData[k]),
                    nd = (o.getTime()/(1000*60*60*24))
                postData[k] = nd                
            }
        }
        const config = {
            headers: {'Authorization': 'Bearer ' + user.token},
            params: {}
        }
        try{
            const response = await axios.post(url,[postData],config)
            const recdata = response.data
                if (recdata) {
                    if(recdata.result){
                        toast.success('Profilo aggiornato correttamente')
                        // Change password if needed
                        if(userData['password']){
                            const url = process.env.REACT_APP_LOCAL_AUTH_URL + apiModel + '/changepassword'
                            let postData = {"uuid":userData['uuid'],"password":userData['password']}
                            const config = {
                                headers: {'Authorization': 'Bearer ' + user.token},
                                params: {}
                            }
                            const response = await axios.post(url,postData,config)
                            const recdata = response.data
                            if (recdata) {
                                toast.success('Password aggiornata correttamente')
                            } else {
                                toast.error('Errore durante il cambio della password')
                            }
                        }
                    } else {
                        toast.error('Errore durante l\'aggiornamento del profilo')
                    }
                }
        } catch (error) {
            if(error.response.data.error){
                console.log(error.response.data.error.description)
                toast.error(error.response.data.error.description)
            } else {
                console.log('Errore durante l\'aggiornamento del profilo')
                toast.error('Errore durante l\'aggiornamento del profilo')
            }
        }
    }

  return (<>
     <div className='container'>
     <ToastContainer
          className='toast-raised'
          position='bottom-right'
          theme='dark'
        />

        {/* <div className={"modal custom-modal border border-danger" + (confirmRegistered ? "" : " fade")} id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="false">
          <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content wl-form text-white border border-even shadow">
                  <div className="modal-header justify-content-center">
                      <h5 className="modal-title">Registrazione conclusa con successo!</h5>
                  </div>
                  <div className="modal-body text-center">
                  <Link className='even-link d-block mb-5' to='/'><span className="material-symbols-rounded text-even d-block mt-5">home</span>
                      <h6 className='h6'>Torna alla home per accedere</h6></Link>
                  </div>
                  <div className="modal-footer" style={{height: '50px'}}>
                  </div>
              </div>
            </div>
          </div> */}

        {<div className={'row' + (!user.capabilities.includes('cap_admin') ? ' font-futura':'')}>
        <div className='col-12 col-md-8 offset-md-2 col-lg-5 m-lg-auto p-2'>
            
            <div className={user.capabilities.includes('cap_admin') ? 'mt-4':'mt-5'}>
                <form className='wl-form rounded shadow p-2'>

                <div className='w-100 d-flex justify-content-end mt-1'>
                    <button className='btn mcr-btn border-even btn-with-icon ms-0' title="Torna alla home" onClick={(e) => {backtoHome(e)}}><span className="material-symbols-rounded">home</span></button>
                </div>


                <h4 className='h4 text-center mt-0 mb-4'>Il mio profilo:</h4>

                <input type='hidden' name='uuid' value={userData.uuid} placeholder='uuid...' readOnly/>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="username">Email (username):</label>
                    </div>
                    <div className='col-8'>
                    <Tooltip disableFocusListener enterTouchDelay={1} arrow title="Contatta l'amministrazione per modificare questo campo">
                        <input type='text' autoComplete='username' value={userData.username} className={'form-control' + (!validation['username'].valid && validation['username'].touched ? ' is-invalid': '')} name='username' placeholder='Email...' onChange={(e)=>{handleUserChange(e)}} readOnly/>
                    </Tooltip>
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['username'].valid || !validation['username'].touched ? ' d-none': '')}>{validation['username'].message}</span>
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="given_name">Nome:</label>
                    </div>
                    <div className='col-8'>
                    <input type='text' autoComplete='given_name' value={userData.given_name} className={'form-control' + (!validation['given_name'].valid && validation['given_name'].touched ? ' is-invalid': '')} name='given_name' placeholder='Nome...' onChange={(e)=>{handleUserChange(e)}} />
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['given_name'].valid || !validation['given_name'].touched ? ' d-none': '')}>{validation['given_name'].message}</span>
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="family_name">Cognome:</label>
                    </div>
                    <div className='col-8'>
                    <input type='text' autoComplete='family_name' value={userData.family_name} className={'form-control' + (!validation['family_name'].valid && validation['family_name'].touched ? ' is-invalid': '')} name='family_name' placeholder='Cognome...' onChange={(e)=>{handleUserChange(e)}} />
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['family_name'].valid || !validation['family_name'].touched ? ' d-none': '')}>{validation['family_name'].message}</span>
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="telephone">Telefono:</label>
                    </div>
                    <div className='col-8'>
                    <input type='text' autoComplete='telephone' value={userData.telephone} className={'form-control' + (!validation['telephone'].valid && validation['telephone'].touched ? ' is-invalid': '')} name='telephone' placeholder='Telefono...' onChange={(e)=>{handleUserChange(e)}} />
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['telephone'].valid || !validation['telephone'].touched ? ' d-none': '')}>{validation['telephone'].message}</span>
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="birth_date">Data di nascita:</label>
                    </div>
                    <div className='col-8'>
                    <input type='date' autoComplete='birth_date' value={userData.birth_date} className={'form-control' + (!validation['birth_date'].valid && validation['birth_date'].touched ? ' is-invalid': '')} name='birth_date' placeholder='' onChange={(e)=>{handleUserChange(e)}} />
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['birth_date'].valid || !validation['birth_date'].touched ? ' d-none': '')}>{validation['birth_date'].message}</span>
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="tax_id_code">Codice fiscale:</label>
                    </div>
                    <div className='col-8'>
                    <input type='text' autoComplete='tax_id_code' value={userData.tax_id_code} className={'form-control' + (!validation['tax_id_code'].valid && validation['tax_id_code'].touched ? ' is-invalid': '')} name='tax_id_code' placeholder='Cod. fiscale...' onChange={(e)=>{handleUserChange(e)}} />
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['tax_id_code'].valid || !validation['tax_id_code'].touched ? ' d-none': '')}>{validation['tax_id_code'].message}</span>
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="address_street">Indirizzo:</label>
                    </div>
                    <div className='col-8'>
                    <input type='text' autoComplete='address_street' value={userData.address_street} className={'form-control' + (!validation['address_street'].valid && validation['address_street'].touched ? ' is-invalid': '')} name='address_street' placeholder='Via, Piazza...' onChange={(e)=>{handleUserChange(e)}} />
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['address_street'].valid || !validation['address_street'].touched ? ' d-none': '')}>{validation['address_street'].message}</span>
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="address_number">Numero civico:</label>
                    </div>
                    <div className='col-8'>
                    <input type='text' autoComplete='address_number' value={userData.address_number} className={'form-control' + (!validation['address_number'].valid && validation['address_number'].touched ? ' is-invalid': '')} name='address_number' placeholder='Numero civico...' onChange={(e)=>{handleUserChange(e)}} />
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['address_number'].valid || !validation['address_number'].touched ? ' d-none': '')}>{validation['address_number'].message}</span>
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="address_city">Città:</label>
                    </div>
                    <div className='col-8'>
                    <input type='text' autoComplete='address_city' value={userData.address_city} className={'form-control' + (!validation['address_city'].valid && validation['address_city'].touched ? ' is-invalid': '')} name='address_city' placeholder='Città di residenza...' onChange={(e)=>{handleUserChange(e)}} />
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['address_city'].valid || !validation['address_city'].touched ? ' d-none': '')}>{validation['address_city'].message}</span>
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="address_zip">CAP:</label>
                    </div>
                    <div className='col-8'>
                    <input type='number' autoComplete='address_zip' value={userData.address_zip} className={'form-control' + (!validation['address_zip'].valid && validation['address_zip'].touched ? ' is-invalid': '')} name='address_zip' placeholder='Cod. postale...' onChange={(e)=>{handleUserChange(e)}} />
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['address_zip'].valid || !validation['address_zip'].touched ? ' d-none': '')}>{validation['address_zip'].message}</span>
                    </div>
                </div>

                <hr className='my-4' style={{borderWidth: '3px'}} />

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="password">Cambio Password:</label>
                    </div>

                    <div className='col-8'>
                        <div className='input-group'>
                            <input type={passwordInputType} autoComplete='new-password' value={userData.password} className={'form-control' + (!validation['password'].valid && validation['password'].touched ? ' is-invalid': '')} name='password' placeholder='Password...' onChange={(e)=>{handleUserChange(e)}} />
                            <div className='input-group-text' style={{cursor: 'pointer'}} onClick={()=>{setPasswordInputType(passwordInputType==='password'?'text':'password')}}>
                                <span className="material-symbols-rounded text-red">{passwordInputType==='password'?'visibility':'visibility_off'}</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['password'].valid || !validation['password'].touched ? ' d-none': '')}>{validation['password'].message}</span>
                    </div>

                    <div className='col-8 offset-4 mt-3'>
                        <div className='input-group'>
                            <input type={passwordInputType} autoComplete='new-password' value={userData.password2} className={'form-control' + (!validation['password2'].valid && validation['password2'].touched ? ' is-invalid': '')} name='password2' placeholder='Ripeti Password...' onChange={(e)=>{handleUserChange(e)}} />
                            <div className='input-group-text' style={{cursor: 'pointer'}} onClick={()=>{setPasswordInputType(passwordInputType==='password'?'text':'password')}}>
                                <span className="material-symbols-rounded text-red">{passwordInputType==='password'?'visibility':'visibility_off'}</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['password2'].valid || !validation['password2'].touched ? ' d-none': '')}>{validation['password2'].message}</span>
                    </div>
                </div>

                <div className='form-footer mt-4 pb-3'>
                <button className='btn mcr-btn warning btn-with-icon ms-0' title="Annulla modifiche non salvate" onClick={(e) => {cancelModifications(e)}} ><span className="material-symbols-rounded">undo</span></button>
                <button className='btn mcr-btn success btn-with-icon ms-0 float-end' title="Salva" onClick={(e) => {handleSave(e);return false}} disabled={!canSubmitUser} ><span className="material-symbols-rounded">done</span></button>
                </div>

                </form>
            </div>
            </div>
        </div>}
     </div>
  </>
  )
}

export default Profile
