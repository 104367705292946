import React, { useEffect } from 'react'
import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { NavLink, useNavigate, useParams } from 'react-router-dom'

const ResetPasswordReset = () => {

/*   const [username,setUsername] = useState("")
  const [validMail,setValidMail] = useState(false)
  const [touchedMail,setTouchedMail] = useState(false) */

  const [password,setPassword] = useState('')
  const [validPassword,setValidPassword] = useState(false)
  const [touchedPassword,setTouchedPassword] = useState(false)

  const [passwordConf,setPasswordConf] = useState('')
  const [validPasswordConf,setValidPasswordConf] = useState(false)
  const [touchedPasswordConf,setTouchedPasswordConf] = useState(false)

  const [sentRequest,setSentRequest] = useState(false)

  const [tokenError,setTokenError] = useState('')
  const [passwordInputType,setPasswordInputType] = useState('password')

  const { token } = useParams()
 
  useEffect(()=>{

    if(token && token.length){
      verifyToken(process.env.REACT_APP_LOCAL_AUTH_URL,token).then((result)=>{
        if(result.error){
          setTokenError(result.error)
        } else {
            setTokenError('')
        }
      }
      )
    } else {
      setTokenError('no_token')
    }
  },[token])

  const navigate = useNavigate()

/*   const checkValidMail = (value) => {
      let rx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
      if(rx.test(value)) return true
      return false
  } */

  const checkValidPassword = (value) => {
    let rx = /^(?=.*[0-9])(?=.*[!@#$%^&*?-])[a-zA-Z0-9!@#$%^&*?-]{8,16}$/
    if(rx.test(value)) return true
    return false
  }

  const checkValidPasswordConf = (value) => {
    if(value === password) return true
    return false
  }

/*   const handleMailChange = (e) => {
    let target = e.target
    setUsername(e.target.value)
    if(checkValidMail(target.value)){
      setValidMail(true)
    } else {
      setValidMail(false)
    }
    if(!touchedMail){
      setTouchedMail(true)
    }
  } */

  const handlePwdChange = (e) => {
    e.preventDefault()
    let value = e.target.value
    setPassword(value)
    if(!touchedPassword){
      setTouchedPassword(true)
    }
    if(checkValidPassword(value)){
      setValidPassword(true)
    } else {
      setValidPassword(false)
    }
  }

  const handlePwdConfChange = (e) => {
    e.preventDefault()
    let value = e.target.value
    setPasswordConf(value)
    if(!touchedPasswordConf){
      setTouchedPasswordConf(true)
    }
    if(checkValidPasswordConf(value)){
      setValidPasswordConf(true)
    } else {
      setValidPasswordConf(false)
    }
  }

  const verifyToken = async(baseurl,token) => {
    const url = baseurl + 'passwordreset/verify';
    const config = {
      params: {}
    }
    const objectData = {
      "token": token
    }
    try{
      const response = await axios.post(url,objectData,config)
      const recdata = response.data
      if(recdata && recdata.result === true) {
        return {"status": 'ok'}
      } else {
        return {"error": 'Errore di rete durante la verifica del token'}
      }
    } catch(error){
      if(error.response.data.error.description){
        return {"error": error.response.data.error.description}
      } else {
        return {"error": 'Errore di rete durante la verifica del token'}
      }
    }
  }

  const pwdReset = async(baseurl,token,password) => {
    const url = baseurl + 'passwordreset/reset';
    const config = {
      params: {}
    }
    const objectData = {
      "token": token,
      "password": password
    }
    try{
      const response = await axios.post(url,objectData,config)
      const recdata = response.data
      if(recdata && recdata.result === true) {
        return {"status": 'ok'}
      } else {
        return {"error": 'Errore di rete durante la richiesta di password reset'}
      }
    } catch(error){
      if(error.response.data.error.description){
        return {"error": error.response.data.error.description}
      } else {
        return {"error": 'Errore di rete durante la richiesta di password reset'}
      }
    }
  }

  const handlePwdReset = async(e) => {
    e.preventDefault()
    setSentRequest(true)
    let result = await pwdReset(process.env.REACT_APP_LOCAL_AUTH_URL,token,password)
    if(result.error){
      setTokenError(result.error)
    } else {
        toast.success('Password reimpostata! Torna alla home per accedere')
    }
  }

  const backtoHome = (e) => {
    e.preventDefault()
    navigate('/')
  }

  return (
    <div className={'hero hero d-flex justify-content-center align-items-center'}>
        <ToastContainer
          className='toast-lowered'
          position='top-center'
          theme='dark'
        />
          {tokenError && <>
            <div className='container-sm' style={{maxWidth: '450px'}}>
              <div className='row'>
                <div className='col-12 text-center p-3 wl-form rounded'>
                  <button className='btn mcr-btn border-even btn-with-icon mt-2' title="Torna alla home" onClick={(e) => {backtoHome(e)}}><span className="material-symbols-rounded">home</span></button>

                  <h4 className='h4 mb-3 mt-2'>Il link per il reset della password non è valido o è scaduto.</h4>
                  <NavLink className="even-link" style={{textDecoration: 'underline'}} to="/forgot-password/request"><h5 className='h5'>Richiedine un'altro qui</h5></NavLink>
                </div>
              </div>
            </div>
          </>}

          {!tokenError && <>
            <div className='container-sm' style={{maxWidth: '450px'}}>
              <div className='row'>
                <div className='col-12 text-center p-3 wl-form rounded'>
                <button className='btn mcr-btn border-even btn-with-icon mt-2' title="Torna alla home" onClick={(e) => {backtoHome(e)}}><span className="material-symbols-rounded">home</span></button>

                  <h4 className='h4 mb-3 mt-2'>Inserisci la tua nuova password e confermala per completare il reset:</h4>
                    <div className='col-12'>
                      <div className={'mt-1 mb-2'}>
                        <form className='wl-form border-even rounded shadow p-2'>
                          
                          {/* <div className='row mt-3 mb-2'>
                            <div className='col-3 d-flex align-items-center'>
                            <label className="form-label" htmlFor="birth_date">Email:</label>
                            </div>
                            <div className='col-9'>
                            <input type='text' autoComplete='email' className={'form-control' + (!validMail && touchedMail ? ' is-invalid': '')} name='username' placeholder='' onKeyUp={(e)=>{handleMailChange(e)}} />
                            </div>
                            <div className='col-12' style={{textAlign: 'right'}}>
                                <span className={'text-even float-end' + (validMail || !touchedMail ? ' d-none': '')}>{'Inserisci una mail valida!'}</span>
                                <span className={'float-end' + (!validMail && touchedMail ? ' d-none': ' text-wl')}>{'|'}</span>
                            </div>
                          </div> */}

                          <div className='row mt-3 mb-2'>
                            <div className='col-3 d-flex align-items-center'>
                            <label className="form-label" htmlFor="password">Password:</label>
                            </div>

                            <div className='col-9'>
                                <div className='input-group'>
                                    <input type={passwordInputType} autoComplete='new-password' value={password} className={'form-control' + (!validPassword && touchedPassword ? ' is-invalid': '')} name='password' placeholder='Password...' onChange={(e)=>{handlePwdChange(e)}} />
                                    <div className='input-group-text' style={{cursor: 'pointer'}} onClick={()=>{setPasswordInputType(passwordInputType==='password'?'text':'password')}}>
                                        <span className="material-symbols-rounded text-red">{passwordInputType==='password'?'visibility':'visibility_off'}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12' style={{textAlign: 'right'}} >
                                <span className={'text-even float-end' + (validPassword || !touchedPassword ? ' d-none': '')}>{'La password deve essere compresa fra 8 e 16 caratteri ed includere un numero e un simbolo scelto tra i seguenti: ! ? @ # $ % ^ & * -'}</span>
                            </div>

                            <div className='col-9 offset-3 mt-3'>
                                <div className='input-group'>
                                    <input type={passwordInputType} autoComplete='new-password' value={passwordConf} className={'form-control' + (!validPasswordConf && touchedPasswordConf ? ' is-invalid': '')} name='passwordConf' placeholder='Ripeti Password...' onChange={(e)=>{handlePwdConfChange(e)}} />
                                    <div className='input-group-text' style={{cursor: 'pointer'}} onClick={()=>{setPasswordInputType(passwordInputType==='password'?'text':'password')}}>
                                        <span className="material-symbols-rounded text-red">{passwordInputType==='password'?'visibility':'visibility_off'}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12' style={{textAlign: 'right'}}>
                                <span className={'text-even float-end' + (validPasswordConf || !touchedPasswordConf ? ' d-none': '')}>{'Le due password non coincidono'}</span>
                            </div>
                        </div>

                          <div className='row mt-3 mb-2'>
                          <div className='col-12 text-center'>
                          <button disabled={/* !validMail || */ !validPassword || !validPasswordConf || sentRequest} className='btn mcr-btn mx-0 font-futura border-even' onClick={(e) => handlePwdReset(e)}>Imposta password</button>
                          </div>
                          </div>
                        </form>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </>}
          

        </div>
  )
}

export default ResetPasswordReset
